@font-face {
  font-family: "Nunito-Regular";
  src: url("./fonts/Nunito-Regular.ttf"); /* IE 9 Compatibility Mode */
  src: url("./fonts/Nunito-Regular.ttf") format("embedded-opentype"),
    /* IE < 9 */ url("./fonts/Nunito-Regular.ttf") format("woff2"),
    /* Super Modern Browsers */ url("./fonts/Nunito-Regular.ttf") format("woff"),
    /* Firefox >= 3.6, any other modern browser */ url("./fonts/Nunito-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Nunito-Bold";
  src: url("./fonts/Nunito-Bold.ttf");
}
* {
  font-family: "Nunito-Regular", sans-serif !important;
}