@import "~antd/dist/antd.css";

:root {
    --main-color: #311a2f;
    --secondary-color: #ff007a;
    --back: #64697e;
    --background: #def1f5;
}

*::-webkit-scrollbar {
    width: 8px;
    height: 8px;
}

*::-webkit-scrollbar-thumb {
    background-color: #311A2F;
    border-radius: 2px;
    /*width: 2px;*/
}

.header {
    background-color: white;
    padding: 10px 50px 10px 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.savdo_header {
    background-color: white;
    display: grid;
    grid-template-columns: 250px 1fr 350px;
    justify-items: center;
}

.savdo_area {
    display: grid;
    padding: 0 15px;
    padding-bottom: 15px;
    grid-template-columns: 3fr 1fr;
    grid-gap: 10px;
}

.header input {
    background-color: #f3f3f3 !important;
    border: none !important;
}

::placeholder {
    color: #aaaaaa;
}

.avatar {
    display: flex;
    align-items: center;
    border-right: 1px solid #eff4f7;
    padding-right: 0.5rem;
    height: 44px !important;
}

.avatar h4 {
    font-size: 1em;
    font-weight: 600;
    line-height: 22px;
    margin: 0;
    letter-spacing: 0.01em;
}

.avatar span {
    font-size: 12px;
    color: var(--main-color);
    opacity: 0.5;
    font-weight: 600;
    letter-spacing: 0.01em;
}

.avatar img {
    max-width: 100%;
    max-height: 100%;
    margin-left: 0.75rem;
}

.Body {
    padding: 20px 30px 0px 30px;
    height: calc(100vh - 67px);
    overflow: auto;
}

.action_btn {
    background-color: var(--main-color) !important;
    color: white !important;
    font-size: 12px !important;
    font-weight: 500;
    display: flex !important;
    justify-content: center;
    padding: 15px 13px !important;
    align-items: center;
    border-radius: 25px !important;
    margin-right: 1rem;
}

.ant-btn-primary {
    border: none;
}

.yellow {
    background-color: #ffd748 !important;
    border-color: #ffd748 !important;
    color: #555 !important;
}

.action_btn_red {
    background-color: #ff2626 !important;
    color: white !important;
    font-size: 12px !important;
    font-weight: 500;
    display: flex !important;
    padding: 15px 13px !important;
    justify-content: center;
    align-items: center;
    border-radius: 25px !important;
    border: none;
}

@media only screen and (min-width: 768px) {
    .open_side {
        visibility: hidden;
    }
}

@media only screen and (max-width: 768px) {
    .header {
        padding: 15px 10px 15px 40px;
    }

    .Body {
        padding: 20px 15px 20px 15px;
    }
}

.ant-input-affix-wrapper {
    background-color: #f3f3f3 !important;
    border: none !important;
    color: var(--main-color) !important;
    font-size: 16px !important;
    border-radius: 24.5px !important;
}

.header .ant-input {
    margin-top: 0 !important;
}

.ant-input-affix-wrapper input {
    background-color: #f3f3f3 !important;
}

/* .ant-table-filter-trigger:hover {
  color: white !important;
} */
.copyright {
    text-align: center;
    color: #898989;
    font-family: "Circular Std" !important;
    background-color: white;
    padding: 15px;
}

.copyright span {
    color: var(--main-color);
    font-weight: 700;
    font-size: 16px;
}

@media print {
    .ant-table.ant-table-small .ant-table-thead > tr > th {
        background-color: var(--main-color) !important;
    }
}

.hisobot_area {
    width: 100%;
    position: relative;
    padding: 40px;
    min-height: 70vh;
    background-color: white;
    box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.05);
    border-radius: 5px;
    border-top-left-radius: 0px;
}

.hisobot_btn {
    color: var(--main-color);
    font-size: 17px;
    padding: 10px 22px;
    display: grid;
    cursor: pointer;
    grid-template-columns: auto auto;
    align-items: center;
    grid-gap: 10px;
    background-color: white;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
}

.hisobot_action_btn {
    color: white;
    font-size: 16px;
    padding: 5px 22px;
    display: grid;
    cursor: pointer;
    grid-template-columns: auto auto;
    align-items: center;
    grid-gap: 10px;
    border-radius: 5px;
    background-color: var(--main-color);
}

.green-btn {
    background-color: #1bdc50 !important;
    color: white !important;
    border-color: #1bdc50 !important;
}

.hisobot_btn_active {
    background-color: var(--main-color);
    color: white;
}

.hisobot_btns {
    display: flex;
    grid-gap: 10px;
}

.dot {
    height: 5px;
    width: 5px;
    background-color: var(--main-color);
    border-radius: 50%;
}

::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: var(--main-color) !important;
    opacity: 0.5; /* Firefox */
}

.hisobot_actions {
    display: flex;
    grid-gap: 25px;
}

.avatar-uploader {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #eee;
    cursor: pointer;
    /*width: 250px !important;*/
    height: 97px !important;
}

.avatar-uploader h3 {
    padding-top: 10px;
}

.avatar-uploader h3 svg {
    margin-right: 6px;
}

.img-uploader {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #eee;
    cursor: pointer;
    aspect-ratio: 1/1;
}

.img-uploader .img-uploader__text {
    display: flex;
    flex-direction: column;
}

.ant-upload {
    width: 100% !important;
    height: 100% !important;
    margin: 0 !important;
}

.form_group_upload {
    display: flex !important;
    gap: 30px;
    margin-top: 19px;
    width: 100% !important;
}

.form_group_upload .form_group_images {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.form_group_uploads {
    display: flex !important;
    justify-content: space-between;
    align-items: center;
    gap: 0 16px;
}

.ant-spin {
    color: var(--secondary-color) !important;
}

.ant-spin-dot-item {
    background-color: var(--secondary-color) !important;
}

form {
    overflow: auto;
}

/* .ant-table-row-level-0  {
  background: orange !important;
} */
.ant-table-row-level-1 {
    background: lightblue !important;
}

.ant-table-row-level-2 {
    background: lightgreen !important;
}

.ant-table-row-level-3 {
    background: lightcoral !important;
}