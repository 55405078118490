.hisobot_wrapper {
    background-color: #fff;
    padding: 8px 28px 34px 28px;
    border-radius: 14px;
}

hr {
    border: 1px dashed #e0e0e0;
    margin: 1.5rem 0;
}

.radio_btn {
    border: none;
}

.ant-radio-button-wrapper {
    border: none !important;
    background-color: #f2f2f2 !important;
    margin: 1rem 1rem 0 0;
    border-radius: 24px !important;
    height: 38px;
    display: flex;
    align-items: center;
}

.ant-radio-button-wrapper span:nth-child(2) {
    display: flex;
    align-items: center;
}

.ant-radio-button-wrapper-checked {
    color: #fff !important;
}

.ant-radio-button-wrapper:not(:first-child)::before {
    display: none;
}

.ant-radio-button-wrapper .ant-radio-button {
    background: #ff007a !important;
    border-radius: 24px;
    outline: none;
}

.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):focus-within {
    border-radius: 24px;
}

.ant-radio-button-input {
    color: #fff !important;
}

.ant-radio-group.ant-radio-group-outline {
    justify-content: flex-start !important;
    margin-top: 0.7rem;
}

.ant-picker.ant-picker-range.range_picker {
    background-color: transparent !important;
}

.ant-picker.ant-picker-range.range_picker > .ant-picker-input {
    background-color: #fbf2fb !important;
    border-radius: 25px;
    width: 115px;
}

.ant-picker.ant-picker-range.range_picker
> .ant-picker-input
> input::placeholder {
    color: #000 !important;
    opacity: 1;
}

/* .ant-picker-content { */
/* border: 2px solid var(--secondary-color); */
/* } */

.ant-picker-content th {
    color: #fff;
}

.ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
    border: 1px solid var(--main-color);
}

.ant-picker-cell .ant-picker-cell-inner {
    margin-left: 5px;
    text-align: center;
}

.ant-picker-input-active {
    border-color: #ff007a !important;
}

.ant-form.ant-form-horizontal.hisobot-form {
    margin-bottom: 0 !important;
}

.ant-picker-range .ant-picker-active-bar {
    display: none;
}

.ant-picker-focused {
    box-shadow: none !important;
}

.hisobot-select {
    margin: 0 10px;
    width: 200px !important;
}

.hisobot-select .ant-select-selector {
    height: 36px !important;
}

.hisobot-select__label {
}