.ant-upload.ant-upload-select-picture-card {
    width: 100% !important;
    height: 74px;
    border-width: 2px;
}

.ant-row.ant-form-item .ant-form-item-label {
    width: 100%;
    text-align: left;
}

.btn {
    width: 107px;
    height: 35px;
    font-size: 12px !important;
    font-weight: 500;
    border-radius: 25px;
    border: none;
}

.btn-back,
.btn-back:hover {
    background-color: #fbf2fb;
    color: #2c3e50;
    margin-right: 1rem;
}

.btn-submit,
.btn-submit:hover {
    background-color: var(--main-color);
    color: #f2f2f2;
    margin-left: 1rem;
}

.section_name_input,
.ant-picker,
.react-tel-input .form-control {
    border: none;
    background-color: #fbf2fb;
    border-radius: 20px;
    height: 35px !important;
    margin-top: 5px;
    outline: none;
    padding-left: 1rem;
}

.hisobot_wrapper .ant-input {
    margin-bottom: 1rem;
}

.hisobot_wrapper .ant-input-affix-wrapper.ant-input-password {
    margin: 5px 0 16px 0;
}

.ant-input-affix-wrapper.ant-input-password .ant-input {
    margin: auto 0;
}

.hisobot_wrapper .ant-picker-input > input {
    font-size: 12px !important;
    text-align: center;
}

.ant-input:focus,
.ant-input-focused {
    box-shadow: none;
}

.ant-input-affix-wrapper.ant-input-password {
    height: 40px;
    margin-top: 5px;
}

.ant-input-affix-wrapper.ant-input-password,
.ant-input-affix-wrapper.ant-input-password .ant-input {
    background-color: #fbf2fb !important;
}

label {
    color: #432540 !important;
    font-weight: 600;
    margin-left: 0.5rem;
    font-size: 12px !important;
}

.form_group {
    display: grid;
    grid-template-columns: 1fr;
    /* grid-gap: 5px; */
    /*border: 1px solid red;*/
    margin-bottom: 15px !important;
}

.ant-modal-content {
    border-radius: 14px !important;
}

.ant-modal-header {
    border-radius: 14px 14px 0 0;
    background-color: #fbf2fb;
    height: 40px;
    padding: 10px 25px;
}

.modal_title {
    font-weight: 600;
}

.ant-modal-close {
    top: -8px;
}

.ant-select-selector {
    border: none !important;
    background-color: #fbf2fb !important;
    border-radius: 20px !important;
    height: 40px !important;
    align-items: center;
}

.ant-select {
    width: 100% !important;
    /* margin-bottom: 1rem; */
    font-size: 12px;
}

.ant-modal-mask {
    background: rgba(67, 37, 64, 0.4);
    backdrop-filter: blur(20px);
}
