.sms_wrapper {
  background-color: #fff;
  padding: 8px 28px 34px 28px;
  border-radius: 14px;
}

.sms_wrapper span.ant-input-affix-wrapper.ant-input-password {
  height: 40px;
}
.sms_wrapper .ant-input-affix-wrapper.ant-input-password,
.sms_wrapper .ant-input-affix-wrapper.ant-input-password .ant-input {
  background-color: #fbf2fb !important;
}
.sms_wrapper .sms_input {
  margin-top: 0;
}
.sms_wrapper .section_name_input {
  border: none;
  background-color: #f2f2f2;
  border-radius: 20px;
  height: 34px !important;
  margin-left: 10px;
  font-size: 12px;
}
.sms_btn {
  color: white !important;
  display: flex !important;
  justify-content: center;
  padding: 16px 10px !important;
  align-items: center;
  font-size: 12px;
  border-radius: 25px !important;
}

.btn_excel {
  background: #00a61b !important;
  border: 1px solid #00a61b;
}

.btn_excel:hover,
.btn_excel:focus {
  border: 1px solid #00a61b;
}

.btn_send {
  background: var(--secondary-color);
  margin-left: 1rem;
  border: 1px solid var(--secondary-color);
}

.btn_send:hover,
.btn_send:focus {
  background: var(--secondary-color);
  border: 1px solid var(--secondary-color);
}

.ant-checkbox {
  margin: 0 1.3rem !important;
}

.sms_wrapper .ant-form.ant-form-horizontal {
  margin: 1rem 0 1rem 0 !important;
}

.ant-table-content .ant-checkbox-wrapper > .ant-checkbox {
  margin: 0 !important;
}

.sms_wrapper .ant-table.ant-table-small .ant-table-thead > tr > th:nth-child(1) .ant-table-selection {
  padding-left: 0.9rem !important;
}

.sms_wrapper .ant-table.ant-table-small .ant-table-tbody > tr > td {
  padding: 0 0 0 1rem !important;
}
.sms_wrapper .ant-table.ant-table-small .ant-table-tbody > tr > td:nth-child(1),
.sms_wrapper .ant-table.ant-table-small .ant-table-tbody > tr > td:nth-child(2) {
  padding-left: 0rem !important;
}

.sms_wrapper .ant-table.ant-table-small .ant-table-tbody > tr > td:last-child .ant-input:focus,
.sms_wrapper .ant-table.ant-table-small .ant-table-tbody > tr > td:last-child .ant-input-focused,
.sms_wrapper .ant-table.ant-table-small .ant-table-tbody .ant-input {
  box-shadow: none;
}
.sms_wrapper .ant-table.ant-table-small .ant-table-tbody > tr > td:last-child .ant-input,
.sms_wrapper .ant-table.ant-table-small .ant-table-tbody .ant-input {
  border: none;
  background: transparent;
}

.excel_reader {
  display: flex;
  flex-wrap: wrap;
  padding: 0 0 0 3rem;
  margin: 0 auto;
  width: 300px;
}

.excel_reader label,
.excel_reader input[type="file"] {
  width: 100%;
  margin-bottom: 1rem;
}

.excel_reader input[type="submit"] {
  width: 75%;
}

input[type="file"]::-webkit-file-upload-button,
.excel_reader input[type="submit"] {
  border: none;
  border-radius: 20px;
  padding: 5px 10px;
  background-color: #311a2f !important;
  color: #f2f2f2;
  cursor: pointer;
}

.sms_wrapper .ant-table-thead > tr > th > .ant-select {
  margin: 0 !important;
}

.table_select {
  color: #fff;
  font-weight: 500;
}

.sms_wrapper .ant-select-selector {
  background-color: var(--main-color) !important;
  box-shadow: none !important;
  border: none !important;
}
.sms_wrapper .ant-select-arrow > .anticon > svg {
  font-size: 1em;
  margin: 0;
  color: #fff;
}
