.form-group {
    margin-right: 1rem;
    font-weight: 600;
    margin-bottom: 10px;
}

.ant-select-arrow {
    color: var(--main-color) !important;
}

.ant-input,
.ant-select {
    margin-top: 5px !important;
}

.form_submit {
    margin-top: 42px;
}

.ant-radio-group.ant-radio-group-outline {
    display: flex !important;
    justify-content: space-between !important;
    width: 100% !important;
}

.ant-radio-checked .ant-radio-inner,
.ant-radio-wrapper:hover .ant-radio,
.ant-radio:hover .ant-radio-inner,
.ant-radio-input:focus + .ant-radio-inner {
    border-color: var(--main-color);
}

.ant-radio-input:focus + .ant-radio-inner {
    box-shadow: 0 0 0 3px #311a2f10 !important;
}

.ant-radio-inner:after,
.ant-radio-inner:focus {
    background-color: var(--main-color);
}

.ant-picker-input {
    padding: 0.7rem 0.5rem;
}

.ant-picker-input > input::placeholder {
    font-size: 12px !important;
    font-weight: 600;
    color: var(--secondary-color) !important;
}

.filial_form {
    width: 1062px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.filial_form .form_group {
    margin-bottom: 0.5rem;
    width: 330px;
}

.filial_form .ant-upload.ant-upload-select-picture-card {
    height: 200px;
}

.filial_form .ant-upload.ant-upload-select-picture-card:hover {
    border-color: var(--secondary-color) !important;
}

.form_group_map {
    /*border: 1px solid red !important;*/
    flex-grow: 1 !important;
    margin-top: -21px;

}

#map {
    /*width: 100% !important;*/
    height: 90% !important;
}

.client_form {
    /*border: 1px solid red;*/
    width: 1062px;
    display: flex;
    flex-wrap: wrap;
    gap: 0!important;
    justify-content: space-between;
}

.client_form .form-group {
    /*border: 1px solid red;*/
    width: 248px;
}
