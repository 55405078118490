.login_area {
  background-color: white;
  padding: 40px 50px;
  padding-bottom: 20px;
  text-align: center;
  border-radius: 2px;
  margin: 0 10px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}
.login_area input {
  background-color: #fbf2fb !important;
  border: none !important;
  font-size: 16px !important;
  color: var(--main-color) !important;
}
.login_area input:focus,
.ant-input-affix-wrapper-focused {
  box-shadow: none !important;
}
.login_btn {
  margin-top: 30px !important;
  font-weight: 500;
  cursor: pointer;
}
.login_btn:focus {
  background: var(--main-color) !important;
  color: white;
}
.login_area h2 {
  font-size: 32px;
  text-transform: uppercase;
  color: var(--main-color);
  font-weight: 900;
  margin-bottom: 20px;
}

.ant-input-password {
  background-color: #fbf2fb !important;
  color: var(--main-color) !important;
}

@media only screen and (max-width: 500px) {
  .login_area {
    padding: 20px 30px;
    margin: 0 15px;
  }
}

.ant-input-password-icon {
  color: var(--main-color) !important;
}
