table {
    border-collapse: collapse !important;
    border-spacing: 0;
    width: 100%;
    font-family: "Nunito-Regular", sans-serif !important;
    border-radius: 20px !important;
    font-weight: 500 !important;
}

th,
td {
    /*border-right: 1px solid #ff007a;*/
    text-align: left;
    padding: 10px;
    font-variant: normal;
}

.ant-picker-content th,
.ant-picker-content td {
    border-right: none;
}

th:last-child,
td:last-child {
    border-right: none;
}

tr {
    cursor: pointer;
}

tr:nth-child(even) {
    background-color: #fdecfd;
    /* border-top: 2px dashed #ff007ac7 !important;
    border-bottom: 2px dashed #ff007ac7 !important; */
}

tr:nth-child(odd) {
    background-color: white;
}

thead tr:first-child {
    background-color: var(--main-color) !important;
    color: white;
    border: none !important;
}

.ant-table-row {
    font-family: "Nunito-Bold", sans-serif !important;
    font-weight: 500 !important;
}

.ant-table-row:hover td {
    background-color: rgba(120, 120, 120, .2) !important;
}

.ant-table-row:hover .loupe-btn {
    visibility: visible;
}

.ant-table-row:hover .row-number {
    visibility: hidden;
}

.ant-table-cell:nth-child(2),
.ant-table-cell:nth-child(3),
.ant-table-cell:nth-child(4) {
    padding-left: 1rem !important;
}

.ant-table-cell:nth-child(5),
.ant-table-cell:nth-child(6),
.ant-table-cell:nth-child(7),
.ant-table-cell:nth-child(8),
.ant-table-cell:nth-child(9),
.ant-table-cell:nth-child(10) {
    padding-left: 1rem !important;
}

.ant-table-thead {
    background-color: var(--main-color) !important;
    color: white;
}

.ant-table {
    background: none;
}

.Table_div {
    overflow: auto;
    background-color: white;
    margin: 15px auto;
}

.ant-table.ant-table-small .ant-table-thead > tr > th {
    padding: 0 !important;
    background-color: var(--main-color) !important;
    font-size: 11px !important;
    -webkit-print-color-adjust: exact;
}

.table_head {
    background-color: var(--main-color);
    color: white;
    padding: 10px 8px 10px 14px;
    font-weight: 500 !important;
}

.ant-table-wrapper {
    border-radius: 8px;
    /*border: 2px solid #ff007bc7;*/
}

.circle {
    width: 15px;
    height: 15px;
    margin-right: 10px;
    border-radius: 50%;
}

.c_y {
    background-color: #ffa800;
}

.c_g {
    background-color: #10cb00;
}

.c_r {
    background-color: #ff0000;
}

.c_b {
    background-color: #000;
}

.table_img {
    height: 20px;
}

table th {
    /*text-align: center !important;*/
    font-family: "Nunito-Bold", sans-serif !important;
}

.ant-table.ant-table-small .ant-table-tbody > tr:last-child {
    border-bottom: none !important;
}

.clickRowStyl td {
    background-color: rgba(73, 73, 73, 0.5) !important;
}

.ant-table-row.clickRowStyl:hover .ant-table-cell {
    background-color: rgba(73, 73, 73, 0.5) !important;
}

.clickRowStyl:hover .ant-table-cell {
    background-color: #ff007b04 !important;
}

.ant-table-cell {
    font-size: 12px !important;
    font-weight: 600 !important;
    padding: 6px 8px !important;
}

.ant-table-cell svg {
    font-size: 15px;
    margin: 0 1rem;
}

.inner_table {
    padding: 6px 0 6px 16px;
    border-right: 1px solid #ff007a;
}

.loupe-btn {
    position: absolute;
    border: none;
    background-color: transparent;
    width: 30px;
    visibility: hidden;
    margin-left: -13px;
    margin-top: -25px;
    cursor: pointer;
}

.loupe-btn:hover svg path {
    fill: #FF007A;
}

.loupe-btn svg {
    /*border: 1px solid red;*/
    width: 28px;
    margin-left: -6px;
    height: 28px;
}

.loupe-icon svg path {
    width: 20px;
    border: 1px solid red;

}

.ant-table-row.clickRowStyl:hover .ant-table-cell {
    background-color: rgba(73, 73, 73, 0.5) !important;
}

/* Actions */
.ant-popover-buttons .ant-btn.ant-btn-primary{
    background-color: red;
}